// Core
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

function ArticleCard({ article }) {
  return (
    <article className="card rel">
      <Link
        className={styles.topic}
        to={`/journal/topics/${article.topic.slug}`}
      >
        <span className="block bold link uppercase xsmall">
          {article.topic.name}
        </span>
      </Link>

      <Link className="x" to={`/journal/${article.slug}`}>
        <div className={styles["image-wrapper"]}>
          <div className={styles.image}>
            <GatsbyImage
              alt={article.title}
              image={article.image.gatsbyImageData}
            />
          </div>
        </div>
      </Link>

      <div className="p20">
        <h4 className="mb10 mt0">
          <Link
            className="link"
            to={`/journal/${article.slug}`}
          >
            {article.title}
          </Link>
        </h4>

        <span className="mb10 small text-primary">
          <span className="color-secondary">
            by&nbsp;
          </span>

          <Link
            className="link"
            to={`/journal/authors/${article.author.slug}`}
          >
            {article.author.name}
          </Link>

          <span className="color-secondary mx05 opacity-05 xsmall">
            Ω
          </span>

          <span className="color-secondary">
            {article.publishDate}
          </span>
        </span>

        <p className="color-secondary mb0">
          {article.description.description}
        </p>
      </div>
    </article>
  );
}

ArticleCard.propTypes = {
  article: PropTypes.object.isRequired,
};

export default ArticleCard;
