// Core
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

function Breadcrumbs({ breadcrumbs }) {
  return (
    <nav
      aria-label="breadcrumbs"
      className={styles.breadcrumbs}
    >
      <ol
        className="small"
        itemScope="itemscope"
        itemType="https://schema.org/BreadcrumbList"
        role="list"
      >
        {breadcrumbs.map((breadcrumb, index) => {
          if (index < breadcrumbs.length - 1) {
            return (
              <li
                itemProp="itemListElement"
                itemScope="itemscope"
                itemType="https://schema.org/ListItem"
                key={index}
              >
                <Link
                  className="link-inverted"
                  itemProp="item"
                  to={breadcrumb.url}
                >
                  <meta
                    content={index + 1}
                    itemProp="position"
                  />

                  <span itemProp="name">
                    {breadcrumb.label}
                  </span>
                </Link>

                <span className={styles.breadcrumbs__separator}>
                  /
                </span>
              </li>
            );
          } else {
            return (
              <li
                itemProp="itemListElement"
                itemScope="itemscope"
                itemType="https://schema.org/ListItem"
                key={index}
              >
                <span className="text-primary">
                  <meta
                    content={index + 1}
                    itemProp="position"
                  />
                
                  <span
                    aria-current="page"
                    itemProp="name"
                  >
                    {breadcrumb.label}
                  </span>
                </span>
              </li>
            );
          }
        })}
      </ol>
    </nav>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default Breadcrumbs;
