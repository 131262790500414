// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/

// Core
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ activeClassName, children, partiallyActive, to, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        {...other}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        to={to}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a
      {...other}
      href={to}
      rel="noreferrer noopener"
      target="_blank"
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default Link;
