// Core
import {
  graphql,
  StaticQuery,
} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  Link,
} from "../";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("keydown", this.handleKeydown);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("keydown", this.handleKeydown);
    }
  }

  handleKeydown(event) {
    // If escape key is pressed
    if (event.keyCode === 27) {
      // Close drawer if open
      if (document.getElementById("navbar-hamburger").getAttribute("aria-expanded") === "true") {
        this.toggleDrawer();
      }

      // Close all dropdowns
      const dropdown_triggers = document.querySelectorAll("[data-dropdown-trigger]");
      dropdown_triggers.forEach(button => {
        if (button.getAttribute("aria-expanded") === "true") {
          button.click();
        }
      });
    }
  }

  handleMouseUp() {
    // Set a 50ms timeout to allow original mouse click to execute
    window.setTimeout(() => {
      // Close all dropdowns
      const dropdown_triggers = document.querySelectorAll("[data-dropdown-trigger]");
      dropdown_triggers.forEach(button => {
        if (button.getAttribute("aria-expanded") === "true") {
          button.click();
        }
      });

      // Remove mouseup listener
      window.removeEventListener("mouseup", this.handleMouseUp);
    }, 50);
  }

  handleScroll() {
    if (window) {
      if (
        window.scrollY > 0 &&
        !document.getElementById("navbar").classList.contains(styles["navbar--scrolled"])
      ) {
        document.getElementById("navbar").classList.add(styles["navbar--scrolled"]);
      } else if (window.scrollY === 0) {
        document.getElementById("navbar").classList.remove(styles["navbar--scrolled"]);
      }
    }
  }

  toggleAccordion(event) {
    // The accordion elements
    const accordion = event.currentTarget.nextSibling;
    const button = event.currentTarget;

    // Current accordion state
    const is_open = button.getAttribute("aria-expanded") === "true";

    // Toggle button
    if (is_open) {
      button.setAttribute("aria-expanded", false);
      button.classList.remove(styles["accordion-trigger--expanded"]);
    } else {
      button.setAttribute("aria-expanded", true);
      button.classList.add(styles["accordion-trigger--expanded"]);
    }

    // Toggle accordion
    if (is_open) {
      accordion.setAttribute("aria-hidden", "true");
      accordion.classList.remove(styles["accordion--expanded"]);
      accordion.style.maxHeight = 0;
    } else {
      accordion.removeAttribute("aria-hidden");
      accordion.classList.add(styles["accordion--expanded"]);
      accordion.style.maxHeight = accordion.scrollHeight + "px";
    }
  }

  toggleDrawer() {
    // The drawer elements
    const button = document.getElementById("navbar-hamburger");
    const drawer = document.getElementById("navbar-drawer");

    // Current drawer state
    const is_open = button.getAttribute("aria-expanded") === "true";

    // Toggle button
    button.setAttribute("aria-expanded", !is_open);

    // Toggle drawer
    if (is_open) {
      drawer.setAttribute("aria-hidden", "true");
      drawer.removeAttribute("tabindex");
      drawer.classList.remove(styles["drawer--open"]);
    } else {
      drawer.removeAttribute("aria-hidden");
      drawer.setAttribute("tabindex", "-1");
      drawer.classList.add(styles["drawer--open"]);
    }

    // Toggle drawer overlay
    const drawer_overlay = document.getElementById("navbar-drawer-overlay");
    if (is_open) {
      drawer_overlay.classList.remove(styles["drawer-overlay--visible"]);
    } else {
      drawer_overlay.classList.add(styles["drawer-overlay--visible"]);
    }

    // Toggle body scroll
    if (is_open) {
      document.body.style.removeProperty("overflow-y");
    } else {
      document.body.style.overflowY = "hidden";
    }

    // Close all accordions
    if (is_open) {
      const accordion_triggers = drawer.querySelectorAll("[data-accordion-trigger]");
      accordion_triggers.forEach(button => {
        if (button.getAttribute("aria-expanded") === "true") {
          button.click();
        }
      });
    }
  }

  toggleDropdown(event) {
    // The dropdown elements
    const button = event.currentTarget;
    const dropdown = event.currentTarget.nextSibling;

    // Current dropdown state
    const is_open = button.getAttribute("aria-expanded") === "true";

    // Toggle button
    button.setAttribute("aria-expanded", !is_open);

    // Toggle dropdown
    if (is_open) {
      dropdown.setAttribute("aria-hidden", "true");
      dropdown.classList.remove(styles["dropdown--visible"]);
    } else {
      dropdown.removeAttribute("aria-hidden");
      dropdown.classList.add(styles["dropdown--visible"]);
    }

    // Add mouseup listener
    if (!is_open) {
      window.addEventListener("mouseup", this.handleMouseUp);
    }
  }

  render() {
    const links = this.props.contentfulSettingsGeneralSiteSettings.navbarLinks;
    const logomark = this.props.contentfulSettingsGeneralSiteSettings.logomark.gatsbyImageData;
    const site_name = this.props.contentfulSettingsGeneralSiteSettings.siteName;

    return (
      <header>
        <nav
          aria-label="Main Navigation"
          className={styles.navbar}
          id="navbar"
          itemScope="itemscope"
          itemType="https://schema.org/SiteNavigationElement"
          role="navigation"
        >
          <div className={styles.navbar__inner}>
            <Link
              className="aic flex mra"
              to="/"
            >
              <GatsbyImage
                alt={site_name}
                image={logomark}
                style={{
                  height: "65px",
                  width: "65px",
                }}
              />
            </Link>

            <ul className={styles.navbar__links}>
              {links.map((link, index) => {
                if (typeof link.url !== "string") {
                  return (
                    <li
                      className="mr40"
                      key={index}
                    >
                      <button
                        aria-controls={`navbar-dropdown-${index}`}
                        aria-expanded="false"
                        className={styles["dropdown-trigger"]}
                        data-dropdown-trigger
                        id={`navbar-dropdown-${index}-button`}
                        itemProp="name"
                        onClick={this.toggleDropdown}
                        type="button"
                      >
                        {link.label}
                        &nbsp;
                        <svg
                          aria-hidden="true"
                          fill="none"
                          height="15"
                          role="img"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                          width="15"
                        >
                          <use href="/images/feather-icons.svg#chevron-down" xlinkHref="/images/feather-icons.svg#chevron-down" />
                        </svg>
                      </button>

                      <div
                        aria-hidden="true"
                        aria-labelledby={`navbar-dropdown-${index}-button`}
                        className={`${styles.dropdown} card`}
                        id={`navbar-dropdown-${index}`}
                      >
                        <ul>
                          {link.links.map((child_link, index) => {
                            return (
                              <li
                                className={child_link.button ? styles["dropdown__button-wrapper"] : ""}
                                key={index}
                              >
                                <Link
                                  className={child_link.button ? `button button--${child_link.button} x` : styles.dropdown__link}
                                  itemProp="name"
                                  to={child_link.url}
                                >
                                  {child_link.label}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={link.button ? "mr20" : "mr40"}
                      key={index}
                    >
                      <Link
                        className={link.button ? `button button--${link.button}` : "link bold"}
                        data-button={link.button ? true : false}
                        itemProp="name"
                        to={link.url}
                      >
                        {link.label}
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>

            <button
              aria-expanded="false"
              aria-haspopup="dialog"
              className={`${styles.navbar__hamburger} icon-button`}
              id="navbar-hamburger"
              onClick={this.toggleDrawer}
              type="button"
            >
              <svg
                aria-labelledby="navbar-open-drawer-icon-title"
                fill="none"
                height="24"
                role="img"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="24"
              >
                <title id="navbar-open-drawer-icon-title">Open Main Navigation</title>
                <use href="/images/feather-icons.svg#menu" xlinkHref="/images/feather-icons.svg#menu" />
              </svg>
            </button>
          </div>
        </nav>

        <div
          className={styles["drawer-overlay"]}
          id="navbar-drawer-overlay"
          onClick={this.toggleDrawer}
        >
        </div>

        <div
          aria-hidden="true"
          aria-labelledby="navbar-drawer-title"
          aria-modal="true"
          className={styles.drawer}
          id="navbar-drawer"
          role="dialog"
        >
          <div className={styles.drawer__inner}>
            <div className={styles.drawer__header}>
              <Link
                onClick={this.toggleDrawer}
                to="/"
              >
                <GatsbyImage
                  alt={site_name}
                  image={logomark}
                  style={{
                    height: "65px",
                    width: "65px",
                  }}
                />
              </Link>

              <button
                className={`${styles.navbar__hamburger} icon-button`}
                onClick={this.toggleDrawer}
                type="button"
              >
                <svg
                  aria-labelledby="navbar-close-drawer-icon-title"
                  fill="none"
                  height="24"
                  role="img"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  width="24"
                >
                  <title id="navbar-close-drawer-icon-title">Close Main Navigation</title>
                  <use href="/images/feather-icons.svg#x" xlinkHref="/images/feather-icons.svg#x" />
                </svg>
              </button>
            </div>

            <nav
              aria-label="Main Navigation"
              id="navbar-drawer-title"
            >
              <ul className="pt20">
                {links.map((link, index) => {
                  if (typeof link.url !== "string") {
                    return (
                      <li
                        className={styles["accordion-wrapper"]}
                        key={index}
                      >
                        <button
                          aria-controls={`navbar-drawer-accordion-${index}`}
                          aria-expanded="false"
                          className={styles["accordion-trigger"]}
                          data-accordion-trigger
                          id={`navbar-drawer-accordion-${index}-button`}
                          onClick={this.toggleAccordion}
                          type="button"
                        >
                          {link.label}
                          &nbsp;
                          <svg
                            aria-hidden="true"
                            fill="none"
                            height="24"
                            role="img"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            width="24"
                          >
                            <use href="/images/feather-icons.svg#chevron-down" xlinkHref="/images/feather-icons.svg#chevron-down" />
                          </svg>
                        </button>

                        <div
                          aria-hidden="true"
                          aria-labelledby={`navbar-drawer-accordion-${index}-button`}
                          className={styles.accordion}
                          id={`navbar-drawer-accordion-${index}`}
                        >
                          <ul>
                            {link.links.map((child_link, index) => {
                              return (
                                <li
                                  className={child_link.button ? "p10" : ""}
                                  key={index}
                                >
                                  <Link
                                    className={child_link.button ? `button button--${child_link.button} x` : `${styles.accordion__link} link`}
                                    onClick={this.toggleDrawer}
                                    to={child_link.url}
                                  >
                                    {child_link.label}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        className={link.button ? "mt20" : ""}
                        key={index}
                      >
                        <Link
                          className={link.button ? `button button--${link.button} x` : "link bold py20 x"}
                          onClick={this.toggleDrawer}
                          to={link.url}
                        >
                          {link.label}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default function NavbarWithQuery(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulSettingsGeneralSiteSettings {
            siteName
            logomark {
              gatsbyImageData(layout: FIXED, height: 130, width: 130)
            }
            navbarLinks {
              button
              label
              url
              links {
                button
                label
                url
              }
            }
          }
        }
      `}
      render={data => (
        <Navbar {...data} {...props} />
      )}
    />
  );
}
