/* eslint react/jsx-sort-props: 0 */

// Core
import {
  graphql,
  useStaticQuery,
} from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

function Seo({
  article,
  description,
  image,
  pathname,
  title,
}) {
  const { contentfulSettingsGeneralSiteSettings, site } = useStaticQuery(graphql`
    query {
      contentfulSettingsGeneralSiteSettings {
        siteName
        siteTagline
        description {
          description
        }
        image {
          fixed(height: 628, width: 1200) {
            src
          }
        }
        logotype {
          fixed {
            src
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const logotype = contentfulSettingsGeneralSiteSettings.logotype.fixed.src;
  const site_description = contentfulSettingsGeneralSiteSettings.description.description;
  const site_image = contentfulSettingsGeneralSiteSettings.image.fixed.src;
  const site_name = contentfulSettingsGeneralSiteSettings.siteName;
  const site_tagline = contentfulSettingsGeneralSiteSettings.siteTagline;
  const site_url = site.siteMetadata.siteUrl;

  // Title
  title = pathname === "/" ? `${site_name} | ${site_tagline}` : title;
  if (!title.includes(`${site_name} |`) && !title.includes(`| ${site_name}`)) {
    title = title + ` | ${site_name}`;
  }
  // Pathname
  if (pathname[0] !== "/") {
    pathname = "/" + pathname;
  }
  // Description
  description = typeof description === "string" ? description : site_description;
  // Image
  image = typeof image === "string" ? image : site_image;
  image = "https://" + image.replace("https:", "").replace("http:", "").replace("//", "");

  return (
    <Helmet>
      {/* Standard */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="url" content={site_url + pathname} />
      <link rel="canonical" href={site_url + pathname} />
      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={site_url + pathname} />
      <meta property="og:site_name" content={site_name} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="628" />
      {/* Rich Snippets */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "${site_url}",
            "logo": "${logotype}"
          }
        `}
      </script>
      {article && (
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${site_url + pathname}"
              },
              "headline": "${article.title}",
              "abstract": "${article.description.description}",
              "image": [
                "${article.image.fixed.src}"
               ],
              "datePublished": "${article.publishDatePlain}",
              "author": {
                "@type": "Person",
                "name": "${article.author.name}"
              },
               "publisher": {
                "@type": "Organization",
                "name": "${site_name}",
                "logo": "${logotype}"
              }
            }
          `}
        </script>
      )}
    </Helmet>
  );
}

Seo.propTypes = {
  article: PropTypes.object,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Seo;
