// Core
import {
  graphql,
  useStaticQuery,
} from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
// Third Party Components
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

function ShareButtons({
  image,
  pathname,
  summary,
  title,
}) {
  // Site URL
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const site_url = site.siteMetadata.siteUrl;
  const url = `${site_url}${pathname[0] === "/" ? "" : "/"}${pathname}`;

  return (
    <div className={styles.sharing}>
      <EmailShareButton
        body={summary}
        subject={title}
        url={url}
      >
        <EmailIcon
          bgStyle={{ fill: "transparent" }}
          iconFillColor="var(--gold)"
          round={true}
          size={30}
        />
      </EmailShareButton>

      <PinterestShareButton
        media={image}
        url={url}
      >
        <PinterestIcon
          bgStyle={{ fill: "transparent" }}
          iconFillColor="var(--gold)"
          round={true}
          size={30}
        />
      </PinterestShareButton>

      <FacebookShareButton
        quote={title}
        url={url}
      >
        <FacebookIcon
          bgStyle={{ fill: "transparent" }}
          iconFillColor="var(--gold)"
          round={true}
          size={30}
        />
      </FacebookShareButton>

      <LinkedinShareButton
        source={site_url}
        summary={summary}
        title={title}
        url={url}
      >
        <LinkedinIcon
          bgStyle={{ fill: "transparent" }}
          iconFillColor="var(--gold)"
          round={true}
          size={30}
        />
      </LinkedinShareButton>

      <TwitterShareButton
        title={title}
        url={url}
        via={site_url}
      >
        <TwitterIcon
          bgStyle={{ fill: "transparent" }}
          iconFillColor="var(--gold)"
          round={true}
          size={30}
        />
      </TwitterShareButton>
    </div>
  );
}

ShareButtons.propTypes = {
  image: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ShareButtons;
