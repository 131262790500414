// Core
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

function Pagination({
  current,
  total,
  pathname,
}) {
  return (
    <div className={styles.pagination}>
      {current > 1 && (
        <Link
          className="link rsmall"
          to={`${pathname}${current - 1 === 1 ? "" : `/page/${current - 1}`}`}
        >
          Previous
        </Link>
      )}
      {current === 1 && (
        <span></span>
      )}

      <ul className={styles.pagination__numbers}>
        {Array.from({ length: total }).map((_, i) => {
          if (current - 1 - i <= 1 && current - 1 - i >= -1) {
            if (i + 1 === current) {
              return (
                <li key={i}>
                  <span className="color-secondary">
                    <strong>{i + 1}</strong>
                  </span>
                </li>
              );
            } else {
              return (
                <li key={i}>
                  <Link
                    className="link"
                    to={`${pathname}${i + 1 === 1 ? "" : `/page/${i + 1}`}`}
                  >
                    {i + 1}
                  </Link>
                </li>
              );
            }
          } else {
            return null;
          }
        })}
      </ul>

      {current < total && (
        <Link
          className="link rsmall"
          to={`${pathname}/page/${current + 1}`}
        >
          Next
        </Link>
      )}
      {current === total && (
        <span></span>
      )}
    </div>
  );
}

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

export default Pagination;
