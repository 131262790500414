// Core
import {
  graphql,
  useStaticQuery,
} from "gatsby";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  Link,
} from "../";

function Footer() {
  const { contentfulSettingsGeneralSiteSettings } = useStaticQuery(graphql`
    query {
      contentfulSettingsGeneralSiteSettings {
        siteName
        footerLinks {
          sections {
            title
            links {
              button
              label
              url
            }
          }
          socials {
            type
            url
          }
        }
      }
    }
  `);

  const links = contentfulSettingsGeneralSiteSettings.footerLinks;
  const site_name = contentfulSettingsGeneralSiteSettings.siteName;

  return (
    <footer className={styles.footer}>
      <div className={`${styles.grid} ${styles[`grid--${links.sections.length}`]} container`}>
        {links.sections.map((section, index) => {
          return (
            <div key={index}>
              <h6 className={styles.section__title}>
                {section.title}
              </h6>

              <ul>
                {section.links.map((link, index) => {
                  return (
                    <li
                      className="color-secondary"
                      key={index}
                    >
                      <Link
                        className={link.button ? `button button--${link.button} my10 x` : "link py10"}
                        to={link.url}
                      >
                        {link.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <div className={styles.footer__bottom}>
        <div className="aic container flex jcb">
          <ul className="aic flex">
            {links.socials.map((social, index) => {
              return (
                <li
                  className="aic flex"
                  key={index}
                >
                  <Link to={`${social.url}?ref=omegaholistic.com.au`}>
                    <svg
                      aria-labelledby={`footer-social-${index + 1}`}
                      fill="none"
                      height="24"
                      role="img"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      width="24"
                    >
                      <title id={`footer-social-${index + 1}`}>{social.type}</title>
                      <use href={`/images/feather-icons.svg#${social.type}`} xlinkHref={`/images/feather-icons.svg#${social.type}`} />
                    </svg>
                  </Link>
                </li>
              );
            })}
          </ul>

          <span className="color-secondary noselect uppercase xsmall">
            {site_name} © {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
