// Core
import PropTypes from "prop-types";
import React from "react";

class Lazyiframe extends React.Component {
  constructor(props) {
    super(props);
    this.initialise = this.initialise.bind(this);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      // Initialise iframe after page interaction
      window.addEventListener("keydown", this.initialise);
      window.addEventListener("mousemove", this.initialise);
      window.addEventListener("scroll", this.initialise);
      window.addEventListener("touchstart", this.initialise);
    }
  }

  initialise() {
    // Remove event listeners
    window.removeEventListener("keydown", this.initialise);
    window.removeEventListener("mousemove", this.initialise);
    window.removeEventListener("scroll", this.initialise);
    window.removeEventListener("touchstart", this.initialise);

    // Create iframe
    const iframe = document.createElement("iframe");
    iframe.height = this.props.height;
    iframe.loading = "lazy";
    iframe.src = this.props.src;
    iframe.title = this.props.title;
    iframe.width = this.props.width;

    // Inject iframe
    document.getElementById(this.props.id).replaceWith(iframe);
  }

  render() {
    return (
      <div
        id={this.props.id}
        style={{
          height: this.props.height,
          width: this.props.width,
        }}
      >
      </div>
    );
  }
}

Lazyiframe.propTypes = {
  height: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Lazyiframe;
